.hamburger{
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: @x05;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  outline: 0;

  &:focus{
    outline: 0;
  }

  &-box{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    top: 3px;
  }

  &-inner{
    position: absolute;
    width: 40px;
    height: 4px;
    transition-property: transform;
    border-radius: 0;
    background-color: @color;
    top: 50%;
    display: block;
    margin-top: -2px;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: 75ms;

    &:before{
      display: block;
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: 0;
      background-color: @color;
      top: -10px;
      transition: top 75ms ease .12s,opacity 75ms ease;
    }

    &:after{
      display: block;
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: 4px;
      background-color: @color;
      bottom: -10px;
      transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
    }
  }

  &.is-active{

    .hamburger-inner{
      background-color: @color;
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(45deg);

      &:before{
        background-color: @color;
        top: 0;
        transition: top 75ms ease,opacity 75ms ease .12s;
        opacity: 0;
      }

      &:after{
        background-color: @color;
        bottom: 0;
        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
        transform: rotate(-90deg);
      }
    }
  }
}