.block{
  padding: @x5 0;

  &--white{
    background-color: @white;
  }

  &--gray{
    .bg('gray.jpg', 0, 0, repeat, @gray);
    background-size: 500px;
  }

  h2 + p{
    max-width: 751px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contact{

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: @x3;

    li{
      margin-bottom: @x1;
      display: flex;
      align-items: center;
    }

    a{
      color: @color;
    }

    i{
      margin-right: @x2;
      font-size: 2.2rem;

      &.icon-pin{
        padding-left: 1px;
        padding-right: 4px;
      }
    }
  }
}