@charset "UTF-8";

// Default Variables

@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "../img/";
@slick-arrow-color: black;
@slick-dot-color: black;
@slick-dot-color-active: @slick-dot-color;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 1;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.5;

/* Slider */
.slick-loading .slick-list{
    background: #fff url('@{slick-loader-path}ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    bottom: -@x3;

    @media @desktop{
        bottom: @x3;
    }

    &:before{
        color: @black;
        font-size: 1.6rem;
    }
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: @slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: @slick-opacity-not-active;
    }


}

.slick-prev {
    .icon-pseudo(arrow-left, before);
    left: 0;

    @media @desktop{
        left: -380px;
    }

}

.slick-next {
    .icon-pseudo(arrow-right, before);
    left: 35px;

    @media @desktop{
        left: -345px;
    }
}
