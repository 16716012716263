@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #32327B;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1c1c45;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #32327B !important;
}
a.bg-primary:hover,
button.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:focus {
  background-color: #232357 !important;
}
.bg-secondary {
  background-color: #C9252C !important;
}
a.bg-secondary:hover,
button.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:focus {
  background-color: #9e1d23 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover,
button.bg-success:hover,
a.bg-success:focus,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover,
button.bg-info:hover,
a.bg-info:focus,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover,
button.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:hover,
button.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
button.bg-light:hover,
a.bg-light:focus,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
button.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #32327B !important;
}
.border-secondary {
  border-color: #C9252C !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #32327B !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #1c1c45 !important;
}
.text-secondary {
  color: #C9252C !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #88191e !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #19692b !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #b98b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.grid-column,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  --gallery-block--gutter-size: 3rem;
}
.wrapper {
  flex: 1;
  padding-top: 80px;
}
@media screen and (min-width: 991px) {
  .wrapper {
    padding-top: 115px;
  }
}
.clear {
  clear: both;
}
.hidden {
  display: none;
}
.transition {
  transition: 0.3s all;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.fleft {
  float: left;
}
.fright {
  float: right;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 3rem;
}
.wp-block-columns.large-gap {
  align-items: center !important;
  gap: 3rem;
}
@media screen and (min-width: 1200px) {
  .wp-block-columns.large-gap {
    gap: 13rem;
  }
}
.wp-block-columns.reverse {
  flex-direction: column-reverse;
}
@media (min-width: 782px) {
  .wp-block-columns.reverse {
    flex-direction: row;
  }
}
p + .wp-block-buttons {
  margin-top: 30px;
}
.has-medium-font-size {
  font-size: 2.2rem;
}
html[lang="de-DE"] .header__sidebar .wp-block-buttons,
html[lang="en-GB"] .header__sidebar .wp-block-buttons {
  display: none !important;
}
html[lang="de-DE"] .header__sidebar select,
html[lang="en-GB"] .header__sidebar select {
  margin-right: 0;
}
html[lang="de-DE"] .block-hero h1,
html[lang="en-GB"] .block-hero h1 {
  margin-bottom: 0;
}
@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'), url('../fonts/iconfont.woff2') format('woff2'), url('../fonts/iconfont.woff') format('woff'), url('../fonts/iconfont.ttf') format('truetype'), url('../fonts/iconfont.svg#iconfont') format('svg');
}
.icon-base-pseudo,
.icon-arrow-left:before,
.icon-arrow-right:before,
.icon-facebook:before,
.icon-instagram:before,
.icon-mail:before,
.icon-phone:before,
.icon-pin:before,
.icon-quote-left:before,
.wp-block-quote:before,
.slick-prev:before,
.slick-next:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}
.icon-arrow-left {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-arrow-left:before {
  content: "\E001";
}
.icon-arrow-right {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-arrow-right:before {
  content: "\E002";
}
.icon-facebook {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-facebook:before {
  content: "\E003";
}
.icon-instagram {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-instagram:before {
  content: "\E004";
}
.icon-mail {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-mail:before {
  content: "\E005";
}
.icon-phone {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-phone:before {
  content: "\E006";
}
.icon-pin {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-pin:before {
  content: "\E007";
}
.icon-quote-left {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
.icon-quote-left:before {
  content: "\E008";
}
html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
}
html,
body {
  font-family: 'Tinos', serif;
  color: #000;
}
.italic {
  font-style: italic;
}
.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.extrabold {
  font-weight: 800;
}
.black {
  font-weight: 900;
}
.h1 {
  font-family: 'Parisienne', serif;
  font-weight: 400;
  font-size: 4rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .h1 {
    font-size: 6rem;
  }
}
@media screen and (min-width: 991px) {
  .h1 {
    font-size: 9.5rem;
  }
}
.h2 {
  font-family: 'Parisienne', serif;
  font-weight: 400;
  font-size: 4rem;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .h2 {
    font-size: 6.4rem;
  }
}
.h2 + p {
  margin-top: -30px;
}
.h2 a {
  color: #000;
  text-decoration: none;
  transition: 0.3s all;
}
.h2 a:hover {
  text-decoration: none;
  color: #32327B;
}
.h3 {
  font-family: 'Tinos', serif;
  font-size: 2.2rem;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .h3 {
    font-size: 2.8rem;
  }
}
.h4 {
  font-family: 'Tinos', serif;
}
.h5 {
  font-family: 'Tinos', serif;
}
.h6 {
  font-family: 'Tinos', serif;
}
h1 {
  font-family: 'Parisienne', serif;
  font-weight: 400;
  font-size: 4rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 6rem;
  }
}
@media screen and (min-width: 991px) {
  h1 {
    font-size: 9.5rem;
  }
}
h2 {
  font-family: 'Parisienne', serif;
  font-weight: 400;
  font-size: 4rem;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 6.4rem;
  }
}
h2 + p {
  margin-top: -30px;
}
h2 a {
  color: #000;
  text-decoration: none;
  transition: 0.3s all;
}
h2 a:hover {
  text-decoration: none;
  color: #32327B;
}
h3 {
  font-family: 'Tinos', serif;
  font-size: 2.2rem;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 2.8rem;
  }
}
h4 {
  font-family: 'Tinos', serif;
}
h5 {
  font-family: 'Tinos', serif;
}
h6 {
  font-family: 'Tinos', serif;
}
img {
  max-width: 100%;
  height: auto;
}
.wp-block-quote {
  margin-bottom: 30px;
  padding-left: 6rem;
  position: relative;
}
.wp-block-quote:before {
  content: "\E008";
}
.wp-block-quote:before {
  position: absolute;
  left: 0;
  top: -15px;
  font-size: 30px;
}
.wp-block-quote + p {
  margin-bottom: 0;
  padding-left: 6rem;
}
.wp-block-quote + p + p {
  padding-left: 6rem;
  margin-bottom: 0;
}
.btn {
  display: inline-block;
  text-transform: uppercase;
  padding: 7.5px 30px;
  text-decoration: none;
  outline: 0;
  transition: 0.3s all;
  border-radius: 0;
}
.btn:focus {
  outline: 0;
}
.btn:hover {
  text-decoration: none;
}
.btn-primary {
  display: inline-block;
  text-transform: uppercase;
  padding: 7.5px 30px;
  text-decoration: none;
  outline: 0;
  transition: 0.3s all;
  border-radius: 0;
  background-color: #C9252C;
  color: #fff;
}
.btn-primary:focus {
  outline: 0;
}
.btn-primary:hover {
  text-decoration: none;
}
.btn-primary:hover {
  background-color: #9e1d23;
}
.wp-block-button {
  padding: 0;
}
.wp-block-button.btn-primary .wp-block-button__link {
  display: inline-block;
  text-transform: uppercase;
  padding: 7.5px 30px;
  text-decoration: none;
  outline: 0;
  transition: 0.3s all;
  border-radius: 0;
  background-color: #C9252C;
  color: #fff;
}
.wp-block-button.btn-primary .wp-block-button__link:focus {
  outline: 0;
}
.wp-block-button.btn-primary .wp-block-button__link:hover {
  text-decoration: none;
}
.wp-block-button.btn-primary .wp-block-button__link:hover {
  background-color: #9e1d23;
}
.footer {
  background-color: #32327B;
  color: #fff;
  padding: 30px 0;
  text-align: center;
}
.footer a {
  color: #fff;
}
.footer a i {
  font-size: 3rem;
  margin: 0 1.5rem;
}
.footer__copy {
  margin-top: 22.5px;
}
.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 7.5px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  outline: 0;
}
.hamburger:focus {
  outline: 0;
}
.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  top: 3px;
}
.hamburger-inner {
  position: absolute;
  width: 40px;
  height: 4px;
  transition-property: transform;
  border-radius: 0;
  background-color: #000;
  top: 50%;
  display: block;
  margin-top: -2px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
.hamburger-inner:before {
  display: block;
  content: "";
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 0;
  background-color: #000;
  top: -10px;
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}
.hamburger-inner:after {
  display: block;
  content: "";
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #000;
  bottom: -10px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger.is-active .hamburger-inner {
  background-color: #000;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.hamburger.is-active .hamburger-inner:before {
  background-color: #000;
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
  opacity: 0;
}
.hamburger.is-active .hamburger-inner:after {
  background-color: #000;
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}
.header {
  background-color: #fff;
  padding: 7.5px 0;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 9;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 991px) {
  .header {
    height: 115px;
  }
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo img {
  width: 100px;
}
@media screen and (min-width: 991px) {
  .header__logo img {
    width: 155px;
  }
}
@media screen and (min-width: 991px) {
  .header__hamburger {
    display: none;
  }
}
.header__menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s opacity;
  background-color: #32327B;
  list-style-type: none;
  margin: 0;
  padding: 30px 15px 210px 15px;
  left: 0;
  top: 80px;
  height: calc(100vh - 80px);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 991px) {
  .header__menu {
    opacity: 1;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    background-color: transparent;
    flex-direction: row;
    padding: 0;
    height: initial;
    width: initial;
  }
}
@media screen and (min-width: 1200px) {
  .header__menu > li {
    margin-right: 22.5px;
  }
}
@media screen and (min-width: 1200px) {
  .header__menu > li:last-child {
    margin-right: 0;
  }
}
.header__menu > li > a {
  display: inline-block;
  padding: 7.5px;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  position: relative;
  transition: 0.3s all;
}
@media screen and (min-width: 991px) {
  .header__menu > li > a {
    color: #000;
  }
}
.header__menu > li > a:after {
  content: "";
  position: absolute;
  width: calc(100% - 15px);
  height: 1px;
  background-color: #32327B;
  bottom: 8px;
  left: 7.5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.header__menu > li > a:hover {
  text-decoration: none;
  color: #fff;
}
@media screen and (min-width: 991px) {
  .header__menu > li > a:hover {
    color: #32327B;
  }
}
.header__menu > li > a:hover:after {
  opacity: 1;
  visibility: visible;
}
.header__sidebar {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: calc(-100vh + 128px);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
}
@media screen and (min-width: 991px) {
  .header__sidebar {
    position: relative;
    opacity: 1;
    visibility: visible;
    left: auto;
    right: auto;
    bottom: auto;
    width: initial;
    flex-direction: row;
    padding-bottom: 0;
    margin: 0;
  }
}
.header__sidebar select {
  margin-bottom: 15px;
  text-transform: uppercase;
  height: 40px;
  border: 1px solid #000;
  padding: 0 15px;
}
@media screen and (min-width: 991px) {
  .header__sidebar select {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .header__sidebar select {
    margin-right: 30px;
  }
}
.header__sidebar select:hover,
.header__sidebar select:focus {
  outline: 0;
}
body.admin-bar-showing .header {
  top: 46px;
}
@media screen and (min-width: 782px) {
  body.admin-bar-showing .header {
    top: 32px;
  }
}
body.menu-open {
  height: 100%;
  overflow-y: hidden;
}
body.menu-open .header__menu {
  opacity: 1;
  visibility: visible;
}
body.menu-open .header__sidebar {
  opacity: 1;
  visibility: visible;
}
.block {
  padding: 45px 0;
}
.block--white {
  background-color: #fff;
}
.block--gray {
  background: url('../img/gray.jpg') 0 0 repeat #F6F6F6;
  background-size: 500px;
}
.block h2 + p {
  max-width: 751px;
  margin-left: auto;
  margin-right: auto;
}
.contact ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
.contact ul li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.contact ul a {
  color: #000;
}
.contact ul i {
  margin-right: 22.5px;
  font-size: 2.2rem;
}
.contact ul i.icon-pin {
  padding-left: 1px;
  padding-right: 4px;
}
.block-cta {
  position: relative;
  background-size: cover;
  background-position: 50% 30%;
  background-color: #32327B;
  color: #fff;
  padding: 60px;
}
.block-cta .lazyblock-inner-blocks > * {
  position: relative;
  z-index: 1;
}
.block-cta p {
  margin-bottom: 0;
}
.block-cta .wp-block-columns {
  margin-bottom: 0;
  align-items: center !important;
}
.block-cta:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 123, 0.85);
}
.block-cta .wp-block-columns {
  flex-direction: column;
  text-align: center;
}
@media screen and (min-width: 991px) {
  .block-cta .wp-block-columns {
    text-align: left;
    flex-direction: row;
  }
}
.block-hero {
  position: relative;
  background-size: cover;
  background-position: 50% 30%;
  background-color: #32327B;
}
.block-hero .lazyblock-inner-blocks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 45px 0;
}
@media screen and (min-width: 991px) {
  .block-hero .lazyblock-inner-blocks {
    min-height: 500px;
  }
}
.block-hero .lazyblock-inner-blocks > * {
  position: relative;
  z-index: 1;
}
.block-hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 123, 0.75);
}
.block-hero h1 {
  color: #fff;
  margin-bottom: 15px;
}
@media screen and (min-width: 991px) {
  .block-hero h1 {
    margin-bottom: 30px;
  }
}
.block-slider {
  margin-top: 60px;
}
.block-slider__content h3,
.block-slider__content p {
  /*.center;

      @media @notebook{
        text-align: left;
      }*/
}
.block-slider .slick-slide:focus,
.block-slider .slick-slide:focus-visible {
  outline: 0;
}
.block-slider .slick-list {
  margin: 0 -15px;
}
.block-slider img {
  padding: 0 15px;
}
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}
[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}
[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}
[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}
[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}
[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}
[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}
[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}
[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}
[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}
[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}
[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}
[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}
[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}
[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}
[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}
[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}
[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}
[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}
[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}
[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}
[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}
[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}
[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}
[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}
[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}
[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}
[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}
[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}
[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}
[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}
[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}
[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}
[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}
[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}
[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}
[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}
[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}
[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}
[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}
[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}
[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}
[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}
[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}
[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}
[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}
[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}
[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}
[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}
[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}
[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}
[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}
[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}
[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}
[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}
[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}
[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}
[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}
[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}
[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}
[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}
[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}
[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}
[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}
[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}
[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}
[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}
[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}
[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}
[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}
[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}
[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}
[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}
[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}
[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}
[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}
[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}
[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}
[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}
[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}
[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}
[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}
[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}
[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}
[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}
[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}
[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}
[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}
[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}
[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}
[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}
[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}
[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}
[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}
[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}
[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}
[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}
[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}
[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}
[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}
[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}
[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}
[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}
[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}
[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}
[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}
[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}
[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}
[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}
[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}
[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}
[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}
[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}
[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}
[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}
[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}
[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}
[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}
[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}
[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}
[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}
[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}
[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}
[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media screen {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}
:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: rgba(0, 0, 0, 0.1);
  --f-spinner-color-2: rgba(17, 24, 28, 0.8);
  --f-spinner-stroke: 2.75;
}
.f-spinner {
  margin: auto;
  padding: 0;
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
}
.f-spinner svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  animation: f-spinner-rotate 2s linear infinite;
}
.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}
.f-spinner svg *:first-child {
  stroke: var(--f-spinner-color-1);
}
.f-spinner svg *:last-child {
  stroke: var(--f-spinner-color-2);
  animation: f-spinner-dash 2s ease-in-out infinite;
}
@keyframes f-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes f-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.f-throwOutUp {
  animation: 0.175s ease-out both f-throwOutUp;
}
.f-throwOutDown {
  animation: 0.175s ease-out both f-throwOutDown;
}
@keyframes f-throwOutUp {
  to {
    transform: translate3d(0, -150px, 0);
    opacity: 0;
  }
}
@keyframes f-throwOutDown {
  to {
    transform: translate3d(0, 150px, 0);
    opacity: 0;
  }
}
.f-zoomInUp {
  animation: var(--f-transition-duration, 0.2s) ease-out 0.1s both f-zoomInUp;
}
.f-zoomOutDown {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-zoomOutDown;
}
@keyframes f-zoomInUp {
  from {
    transform: scale(0.975) translate3d(0, 16px, 0);
    opacity: 0;
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-zoomOutDown {
  to {
    transform: scale(0.975) translate3d(0, 16px, 0);
    opacity: 0;
  }
}
.f-fadeIn {
  animation: var(--f-transition-duration, 0.2s) ease both f-fadeIn;
  z-index: 2;
}
.f-fadeOut {
  animation: var(--f-transition-duration, 0.2s) ease both f-fadeOut;
  z-index: 1;
}
@keyframes f-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeOut {
  100% {
    opacity: 0;
  }
}
.f-fadeSlowIn {
  animation: var(--f-transition-duration, 0.5s) ease both f-fadeSlowIn;
  z-index: 2;
}
.f-fadeSlowOut {
  animation: var(--f-transition-duration, 0.5s) ease both f-fadeSlowOut;
  z-index: 1;
}
@keyframes f-fadeSlowIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeSlowOut {
  100% {
    opacity: 0;
  }
}
.f-fadeFastIn {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-fadeFastIn;
  z-index: 2;
}
.f-fadeFastOut {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-fadeFastOut;
  z-index: 2;
}
@keyframes f-fadeFastIn {
  0% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeFastOut {
  100% {
    opacity: 0;
  }
}
.f-crossfadeIn {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-crossfadeIn;
  z-index: 2;
}
.f-crossfadeOut {
  animation: calc(var(--f-transition-duration, 0.2s)*0.5) linear 0.1s both f-crossfadeOut;
  z-index: 1;
}
@keyframes f-crossfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-crossfadeOut {
  100% {
    opacity: 0;
  }
}
.f-slideIn.from-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideInNext;
}
.f-slideIn.from-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideInPrev;
}
.f-slideOut.to-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideOutNext;
}
.f-slideOut.to-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideOutPrev;
}
@keyframes f-slideInPrev {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes f-slideInNext {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes f-slideOutNext {
  100% {
    transform: translateX(-100%);
  }
}
@keyframes f-slideOutPrev {
  100% {
    transform: translateX(100%);
  }
}
.f-classicIn.from-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicInNext;
  z-index: 2;
}
.f-classicIn.from-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicInPrev;
  z-index: 2;
}
.f-classicOut.to-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicOutNext;
  z-index: 1;
}
.f-classicOut.to-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicOutPrev;
  z-index: 1;
}
@keyframes f-classicInNext {
  0% {
    transform: translateX(-75px);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-classicInPrev {
  0% {
    transform: translateX(75px);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-classicOutNext {
  100% {
    transform: translateX(-75px);
    opacity: 0;
  }
}
@keyframes f-classicOutPrev {
  100% {
    transform: translateX(75px);
    opacity: 0;
  }
}
:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all 0.15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: 0.65;
}
.f-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  position: relative;
  margin: 0;
  padding: 0;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
}
@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}
.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}
.f-button:focus:not(:focus-visible) {
  outline: none;
}
.f-button:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
}
.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: opacity 0.15s ease;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
}
.f-button[disabled] {
  cursor: default;
}
.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}
.f-carousel__nav .f-button.is-prev,
.f-carousel__nav .f-button.is-next,
.fancybox__nav .f-button.is-prev,
.fancybox__nav .f-button.is-next {
  position: absolute;
  z-index: 1;
}
.is-horizontal .f-carousel__nav .f-button.is-prev,
.is-horizontal .f-carousel__nav .f-button.is-next,
.is-horizontal .fancybox__nav .f-button.is-prev,
.is-horizontal .fancybox__nav .f-button.is-next {
  top: 50%;
  transform: translateY(-50%);
}
.is-horizontal .f-carousel__nav .f-button.is-prev,
.is-horizontal .fancybox__nav .f-button.is-prev {
  left: var(--f-button-prev-pos);
}
.is-horizontal .f-carousel__nav .f-button.is-next,
.is-horizontal .fancybox__nav .f-button.is-next {
  right: var(--f-button-next-pos);
}
.is-horizontal.is-rtl .f-carousel__nav .f-button.is-prev,
.is-horizontal.is-rtl .fancybox__nav .f-button.is-prev {
  left: auto;
  right: var(--f-button-next-pos);
}
.is-horizontal.is-rtl .f-carousel__nav .f-button.is-next,
.is-horizontal.is-rtl .fancybox__nav .f-button.is-next {
  right: auto;
  left: var(--f-button-prev-pos);
}
.is-vertical .f-carousel__nav .f-button.is-prev,
.is-vertical .f-carousel__nav .f-button.is-next,
.is-vertical .fancybox__nav .f-button.is-prev,
.is-vertical .fancybox__nav .f-button.is-next {
  top: auto;
  left: 50%;
  transform: translateX(-50%);
}
.is-vertical .f-carousel__nav .f-button.is-prev,
.is-vertical .fancybox__nav .f-button.is-prev {
  top: var(--f-button-next-pos);
}
.is-vertical .f-carousel__nav .f-button.is-next,
.is-vertical .fancybox__nav .f-button.is-next {
  bottom: var(--f-button-next-pos);
}
.is-vertical .f-carousel__nav .f-button.is-prev svg,
.is-vertical .f-carousel__nav .f-button.is-next svg,
.is-vertical .fancybox__nav .f-button.is-prev svg,
.is-vertical .fancybox__nav .f-button.is-next svg {
  transform: rotate(90deg);
}
html.with-fancybox {
  width: auto;
  overflow: visible;
  scroll-behavior: auto;
}
html.with-fancybox body {
  touch-action: none;
}
html.with-fancybox body.hide-scrollbar {
  width: auto;
  margin-right: calc(var(--fancybox-body-margin, 0px) + var(--fancybox-scrollbar-compensate, 0px));
  overflow: hidden !important;
  overscroll-behavior-y: none;
}
.fancybox__container {
  --fancybox-color: #dbdbdb;
  --fancybox-hover-color: #fff;
  --fancybox-bg: rgba(24, 24, 27, 0.98);
  --fancybox-slide-gap: 10px;
  --f-spinner-width: 50px;
  --f-spinner-height: 50px;
  --f-spinner-color-1: rgba(255, 255, 255, 0.1);
  --f-spinner-color-2: #bbb;
  --f-spinner-stroke: 3.65;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  direction: ltr;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #f8f8f8;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: visible;
  z-index: var(--fancybox-zIndex, 1050);
  outline: none;
  transform-origin: top left;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overscroll-behavior-y: contain;
}
.fancybox__container *,
.fancybox__container *::before,
.fancybox__container *::after {
  box-sizing: inherit;
}
.fancybox__container::backdrop {
  background-color: rgba(0, 0, 0, 0);
}
.fancybox__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: var(--fancybox-bg);
  opacity: var(--fancybox-opacity, 1);
  will-change: opacity;
}
.fancybox__carousel {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  min-height: 0;
  z-index: 10;
  overflow-y: visible;
  overflow-x: clip;
}
.fancybox__viewport {
  width: 100%;
  height: 100%;
}
.fancybox__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}
.fancybox__viewport.is-dragging {
  cursor: move;
  cursor: grabbing;
}
.fancybox__track {
  display: flex;
  margin: 0 auto;
  height: 100%;
}
.fancybox__slide {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 var(--fancybox-slide-gap) 0 0;
  padding: 4px;
  overflow: auto;
  overscroll-behavior: contain;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding-top: 40px;
}
.fancybox__slide.has-iframe,
.fancybox__slide.has-video,
.fancybox__slide.has-html5video {
  overflow: hidden;
}
.fancybox__slide.has-image {
  overflow: hidden;
}
.fancybox__slide.has-image.is-animating,
.fancybox__slide.has-image.is-selected {
  overflow: visible;
}
.fancybox__slide::before,
.fancybox__slide::after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}
.fancybox__content {
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 2rem;
  max-width: 100%;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  cursor: default;
  border-radius: 0;
  z-index: 20;
}
.is-loading .fancybox__content {
  opacity: 0;
}
.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}
.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}
.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}
.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}
.fancybox__content [data-selectable],
.fancybox__content [contenteditable] {
  cursor: auto;
}
.fancybox__slide.has-image > .fancybox__content {
  padding: 0;
  background: rgba(0, 0, 0, 0);
  min-height: 1px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: none;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.fancybox__slide.has-image > .fancybox__content > picture > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.is-zooming-in .fancybox__viewport:not(.is-dragging) .fancybox__slide:not(.is-selected) .fancybox__content,
.is-zooming-out .fancybox__slide:not(.is-selected) .fancybox__content {
  visibility: hidden;
}
.is-animating .fancybox__content,
.is-dragging .fancybox__content {
  filter: blur(0px);
  will-change: transform, width, height;
}
.fancybox-image {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  user-select: none;
}
.fancybox__caption {
  align-self: center;
  max-width: 100%;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 0 4px 0;
  overflow-wrap: anywhere;
  line-height: 1.375;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  cursor: auto;
  visibility: visible;
}
.is-loading .fancybox__caption,
.is-closing .fancybox__caption {
  opacity: 0;
  visibility: hidden;
}
.is-compact .fancybox__caption {
  padding-bottom: 0;
}
.f-button.is-close-btn {
  --f-button-svg-stroke-width: 2;
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 40;
}
.fancybox__content > .f-button.is-close-btn {
  --f-button-width: 34px;
  --f-button-height: 34px;
  --f-button-border-radius: 4px;
  --f-button-color: var(--fancybox-color, #fff);
  --f-button-hover-color: var(--fancybox-color, #fff);
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
  position: absolute;
  top: -38px;
  right: 0;
  opacity: 0.75;
}
.is-loading .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}
.is-zooming-out .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}
.fancybox__content > .f-button.is-close-btn:hover {
  opacity: 1;
}
.fancybox__footer {
  padding: 0;
  margin: 0;
  position: relative;
}
.fancybox__footer .fancybox__caption {
  width: 100%;
  padding: 24px;
  opacity: var(--fancybox-opacity, 1);
  transition: all 0.25s ease;
}
.is-compact .fancybox__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: rgba(24, 24, 27, 0.5);
}
.is-compact .fancybox__footer .fancybox__caption {
  padding: 12px;
}
.is-compact .fancybox__content > .f-button.is-close-btn {
  --f-button-border-radius: 50%;
  --f-button-color: #fff;
  --f-button-hover-color: #fff;
  --f-button-outline-color: #000;
  --f-button-bg: rgba(0, 0, 0, 0.6);
  --f-button-active-bg: rgba(0, 0, 0, 0.6);
  --f-button-hover-bg: rgba(0, 0, 0, 0.6);
  --f-button-svg-width: 18px;
  --f-button-svg-height: 18px;
  --f-button-svg-filter: none;
  top: 5px;
  right: 5px;
}
.fancybox__nav {
  --f-button-width: 50px;
  --f-button-height: 50px;
  --f-button-border: 0;
  --f-button-border-radius: 50%;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: transparent;
  --f-button-hover-bg: rgba(24, 24, 27, 0.3);
  --f-button-active-bg: rgba(24, 24, 27, 0.5);
  --f-button-shadow: none;
  --f-button-transition: all 0.15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 26px;
  --f-button-svg-height: 26px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: drop-shadow(1px 1px 1px rgba(24, 24, 27, 0.5));
  --f-button-svg-disabled-opacity: 0.65;
  --f-button-next-pos: 1rem;
  --f-button-prev-pos: 1rem;
  opacity: var(--fancybox-opacity, 1);
}
.fancybox__nav .f-button:before {
  position: absolute;
  content: "";
  top: -30px;
  right: -20px;
  left: -20px;
  bottom: -30px;
  z-index: 1;
}
.is-idle .fancybox__nav {
  animation: 0.15s ease-out both f-fadeOut;
}
.is-idle.is-compact .fancybox__footer {
  pointer-events: none;
  animation: 0.15s ease-out both f-fadeOut;
}
.fancybox__slide > .f-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: var(--f-spinner-top, calc(var(--f-spinner-width) * -0.5)) 0 0 var(--f-spinner-left, calc(var(--f-spinner-height) * -0.5));
  z-index: 30;
  cursor: pointer;
}
.fancybox-protected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  user-select: none;
}
.fancybox-ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  z-index: 40;
  user-select: none;
  pointer-events: none;
}
.fancybox-focus-guard {
  outline: none;
  opacity: 0;
  position: fixed;
  pointer-events: none;
}
.fancybox__container:not([aria-hidden]) {
  opacity: 0;
}
.fancybox__container.is-animated[aria-hidden=false] > *:not(.fancybox__backdrop, .fancybox__carousel),
.fancybox__container.is-animated[aria-hidden=false] .fancybox__carousel > *:not(.fancybox__viewport),
.fancybox__container.is-animated[aria-hidden=false] .fancybox__slide > *:not(.fancybox__content) {
  animation: 0.25s ease 0.1s backwards f-fadeIn;
}
.fancybox__container.is-animated[aria-hidden=false] .fancybox__backdrop {
  animation: 0.35s ease backwards f-fadeIn;
}
.fancybox__container.is-animated[aria-hidden=true] > *:not(.fancybox__backdrop, .fancybox__carousel),
.fancybox__container.is-animated[aria-hidden=true] .fancybox__carousel > *:not(.fancybox__viewport),
.fancybox__container.is-animated[aria-hidden=true] .fancybox__slide > *:not(.fancybox__content) {
  animation: 0.15s ease forwards f-fadeOut;
}
.fancybox__container.is-animated[aria-hidden=true] .fancybox__backdrop {
  animation: 0.35s ease forwards f-fadeOut;
}
.has-iframe .fancybox__content,
.has-map .fancybox__content,
.has-pdf .fancybox__content,
.has-youtube .fancybox__content,
.has-vimeo .fancybox__content,
.has-html5video .fancybox__content {
  max-width: 100%;
  flex-shrink: 1;
  min-height: 1px;
  overflow: visible;
}
.has-iframe .fancybox__content,
.has-map .fancybox__content,
.has-pdf .fancybox__content {
  width: 100%;
  height: 100%;
}
.fancybox__container:not(.is-compact) .has-iframe .fancybox__content,
.fancybox__container:not(.is-compact) .has-map .fancybox__content,
.fancybox__container:not(.is-compact) .has-pdf .fancybox__content {
  width: calc(100% - 120px);
  height: 90%;
}
.has-youtube .fancybox__content,
.has-vimeo .fancybox__content,
.has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}
.has-map .fancybox__content,
.has-pdf .fancybox__content,
.has-youtube .fancybox__content,
.has-vimeo .fancybox__content,
.has-html5video .fancybox__content {
  padding: 0;
  background: rgba(24, 24, 27, 0.9);
  color: #fff;
}
.has-map .fancybox__content {
  background: #e5e3df;
}
.fancybox__html5video,
.fancybox__iframe {
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.fancybox-placeholder {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.f-carousel__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-outline: 0;
  --f-thumb-outline-color: #5eb0ef;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
  --f-thumb-border-radius: 2px;
  --f-thumb-offset: 0px;
  --f-button-next-pos: 0;
  --f-button-prev-pos: 0;
}
.f-carousel__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: 0.5;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
}
.f-carousel__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 20px;
  --f-thumb-clip-width: 46px;
}
.f-thumbs {
  position: relative;
  flex: 0 0 auto;
  margin: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  perspective: 1000px;
  transform: translateZ(0);
}
.f-thumbs .f-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-image: linear-gradient(#ebeff2, #e2e8f0);
  z-index: -1;
}
.f-thumbs .f-spinner svg {
  display: none;
}
.f-thumbs.is-vertical {
  height: 100%;
}
.f-thumbs__viewport {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.f-thumbs__track {
  display: flex;
  will-change: transform;
}
.f-thumbs__slide {
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: var(--f-thumb-width);
  min-width: var(--f-thumb-width);
  height: var(--f-thumb-height);
  overflow: visible;
  cursor: pointer;
}
.f-thumbs__slide.is-loading img {
  opacity: 0;
}
.is-classic .f-thumbs__viewport {
  height: 100%;
}
.is-modern .f-thumbs__track {
  width: max-content;
}
.is-modern .f-thumbs__track::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--left, 0)*1px);
  width: calc(100% - var(--width, 0)*1px);
  cursor: pointer;
}
.is-modern .f-thumbs__slide {
  --clip-path: inset(0 calc((var(--f-thumb-width, 0) - var(--f-thumb-clip-width, 0)) * 0.5 * (1 - var(--progress, 0))) round var(--f-thumb-border-radius, 0));
  transform: translate3d(calc(var(--shift, 0) * -1px), 0, 0);
  transition: none;
  pointer-events: none;
}
.is-modern .f-thumbs__slide > * {
  clip-path: var(--clip-path);
}
.is-modern:not(.is-using-mouse) .f-thumbs__slide:focus-within {
  filter: drop-shadow(-1px 0px 0px var(--f-thumb-outline-color)) drop-shadow(2px 0px 0px var(--f-thumb-outline-color)) drop-shadow(0px -1px 0px var(--f-thumb-outline-color)) drop-shadow(0px 2px 0px var(--f-thumb-outline-color));
}
.is-modern.in-touch .f-thumbs__slide {
  filter: none;
}
.is-modern.is-resting .f-thumbs__slide {
  transition: all 0.33s ease;
}
.is-modern.is-resting .f-thumbs__slide > * {
  transition: all 0.33s ease;
}
.f-thumbs__slide__button {
  appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  border-radius: var(--f-thumb-border-radius);
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  outline: none;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  opacity: var(--f-thumb-opacity);
  transition: opacity 0.2s ease;
}
.f-thumbs__slide__button:hover {
  opacity: var(--f-thumb-hover-opacity);
}
.f-thumbs__slide__button:focus:not(:focus-visible) {
  outline: none;
}
.f-thumbs__slide__button:focus-visible {
  outline: none;
  opacity: var(--f-thumb-selected-opacity);
}
.is-nav-selected .f-thumbs__slide__button {
  opacity: var(--f-thumb-selected-opacity);
}
.is-nav-selected .f-thumbs__slide__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  bottom: 0;
  border: var(--f-thumb-outline, 0) solid var(--f-thumb-outline-color, transparent);
  border-radius: var(--f-thumb-border-radius);
  animation: f-fadeIn 0.2s ease-out;
  z-index: 10;
}
.f-thumbs__slide__img {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--f-thumb-offset);
  box-sizing: border-box;
  pointer-events: none;
  object-fit: cover;
}
.f-thumbs.is-horizontal .f-thumbs__track {
  margin: 0 auto;
  padding: 8px 0 12px 0;
}
.f-thumbs.is-horizontal .f-thumbs__slide {
  margin: 0 var(--f-thumb-gap) 0 0;
}
.f-thumbs.is-vertical .f-thumbs__track {
  flex-wrap: wrap;
  margin: auto 0;
  padding: 0 8px;
}
.f-thumbs.is-vertical .f-thumbs__slide {
  margin: 0 0 var(--f-thumb-gap) 0;
}
.fancybox__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-border-radius: 2px;
  --f-thumb-outline: 2px;
  --f-thumb-outline-color: #ededed;
  position: relative;
  opacity: var(--fancybox-opacity, 1);
  transition: max-height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.fancybox__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: 0.5;
  --f-thumb-hover-opacity: 1;
}
.fancybox__thumbs.is-classic .f-spinner {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
}
.fancybox__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 20px;
  --f-thumb-clip-width: 46px;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
}
.fancybox__thumbs.is-modern .f-spinner {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
}
.fancybox__thumbs.is-horizontal {
  padding: 0 var(--f-thumb-gap);
}
.fancybox__thumbs.is-vertical {
  padding: var(--f-thumb-gap) 0;
}
.is-compact .fancybox__thumbs {
  --f-thumb-width: 64px;
  --f-thumb-clip-width: 32px;
  --f-thumb-height: 48px;
  --f-thumb-extra-gap: 10px;
}
.fancybox__thumbs.is-masked {
  max-height: 0px !important;
}
.is-closing .fancybox__thumbs {
  transition: none !important;
}
.fancybox__toolbar {
  --f-progress-color: var(--fancybox-color, rgba(255, 255, 255, 0.94));
  --f-button-width: 46px;
  --f-button-height: 46px;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: rgba(24, 24, 27, 0.65);
  --f-button-hover-bg: rgba(70, 70, 73, 0.65);
  --f-button-active-bg: rgba(90, 90, 93, 0.65);
  --f-button-border-radius: 0;
  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-filter: drop-shadow(1px 1px 1px rgba(24, 24, 27, 0.15));
  --f-button-svg-fill: none;
  --f-button-svg-disabled-opacity: 0.65;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI Adjusted", "Segoe UI", "Liberation Sans", sans-serif;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px rgba(0, 0, 0, 0.5));
  pointer-events: none;
  z-index: 20;
}
.fancybox__toolbar :focus-visible {
  z-index: 1;
}
.fancybox__toolbar.is-absolute,
.is-compact .fancybox__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.is-idle .fancybox__toolbar {
  pointer-events: none;
  animation: 0.15s ease-out both f-fadeOut;
}
.fancybox__toolbar__column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.fancybox__toolbar__column.is-left,
.fancybox__toolbar__column.is-right {
  flex-grow: 1;
  flex-basis: 0;
}
.fancybox__toolbar__column.is-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.fancybox__infobar {
  padding: 0 5px;
  line-height: var(--f-button-height);
  text-align: center;
  font-size: 17px;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: default;
  user-select: none;
}
.fancybox__infobar span {
  padding: 0 5px;
}
.fancybox__infobar:not(:first-child):not(:last-child) {
  background: var(--f-button-bg);
}
[data-fancybox-toggle-slideshow] {
  position: relative;
}
[data-fancybox-toggle-slideshow] .f-progress {
  height: 100%;
  opacity: 0.3;
}
[data-fancybox-toggle-slideshow] svg g:first-child {
  display: flex;
}
[data-fancybox-toggle-slideshow] svg g:last-child {
  display: none;
}
.has-slideshow [data-fancybox-toggle-slideshow] svg g:first-child {
  display: none;
}
.has-slideshow [data-fancybox-toggle-slideshow] svg g:last-child {
  display: flex;
}
[data-fancybox-toggle-fullscreen] svg g:first-child {
  display: flex;
}
[data-fancybox-toggle-fullscreen] svg g:last-child {
  display: none;
}
:fullscreen [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: none;
}
:fullscreen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}
.f-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  background: var(--f-progress-color, var(--f-carousel-theme-color, #0091ff));
  z-index: 30;
  user-select: none;
  pointer-events: none;
}
/* Slider */
.slick-loading .slick-list {
  background: #fff url('../img/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  bottom: -30px;
}
@media screen and (min-width: 1200px) {
  .slick-prev,
  .slick-next {
    bottom: 30px;
  }
}
.slick-prev:before,
.slick-next:before {
  color: #000;
  font-size: 1.6rem;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.5;
}
.slick-prev {
  left: 0;
}
.slick-prev:before {
  content: "\E001";
}
@media screen and (min-width: 1200px) {
  .slick-prev {
    left: -380px;
  }
}
.slick-next {
  left: 35px;
}
.slick-next:before {
  content: "\E002";
}
@media screen and (min-width: 1200px) {
  .slick-next {
    left: -345px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
