@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
  url('../fonts/iconfont.woff2') format('woff2'),
  url('../fonts/iconfont.woff') format('woff'),
  url('../fonts/iconfont.ttf') format('truetype'),
  url('../fonts/iconfont.svg#iconfont') format('svg');
}

.icon-base-pseudo {
                font-family: "iconfont";
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
                text-decoration: none;
                text-transform: none;
                line-height: 1;
                display: inline-block;
              }

.icon-char(@filename) {
@arrow-left: "\E001";
@arrow-right: "\E002";
@facebook: "\E003";
@instagram: "\E004";
@mail: "\E005";
@phone: "\E006";
@pin: "\E007";
@quote-left: "\E008";

content: @@filename;
}

.icon(@filename, @insert: before) {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
  @pseudo-selector: ~":@{insert}";

  &@{pseudo-selector} {
    &:extend(.icon-base-pseudo);
    .icon-char(@filename);
  }
}

.icon-pseudo(@filename, @insert: before) {
  @pseudo-selector: ~":@{insert}";

&@{pseudo-selector} {
  &:extend(.icon-base-pseudo);
  .icon-char(@filename);
}
}

.icon-arrow-left {
.icon(arrow-left);
}
.icon-arrow-right {
.icon(arrow-right);
}
.icon-facebook {
.icon(facebook);
}
.icon-instagram {
.icon(instagram);
}
.icon-mail {
.icon(mail);
}
.icon-phone {
.icon(phone);
}
.icon-pin {
.icon(pin);
}
.icon-quote-left {
.icon(quote-left);
}
