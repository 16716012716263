@font-family: 'Tinos', serif;
@font-family-heading: 'Parisienne', serif;

html{
	font-size: 10px;
}

body{
	font-size: 1.6rem;
}

html, body{
	font-family: @font-family;
	color: @color;
}

.italic{
	font-style: italic;
}

.thin{
	font-weight: 100;
}

.light{
	font-weight: 300;
}

.regular{
	font-weight: 400;
}

.medium{
	font-weight: 500;
}

.semibold{
	font-weight: 600;
}

.bold{
	font-weight: 700;
}

.extrabold{
	font-weight: 800;
}

.black{
	font-weight: 900;
}

.h1{
	font-family: @font-family-heading;
	font-weight: 400;
	font-size: 4rem;
	line-height: 1;

	@media @tablet{
		font-size: 6rem;
	}

	@media @notebook{
		font-size: 9.5rem;
	}
}

.h2{
	font-family: @font-family-heading;
	font-weight: 400;
	font-size: 4rem;
	margin-bottom: @x3;

	@media @tablet{
		font-size: 6.4rem;
	}

	& + p{
		margin-top: -@x3;
	}

	a{
		color: @color;
		text-decoration: none;
		transition: 0.3s all;

		&:hover{
			text-decoration: none;
			color: @primary;
		}
	}
}

.h3{
	font-family: @font-family;
	font-size: 2.2rem;
	font-weight: 400;

	@media @tablet{
		font-size: 2.8rem;
	}
}

.h4{
	font-family: @font-family;
}

.h5{
	font-family: @font-family;
}

.h6{
	font-family: @font-family;
}

h1{
	.h1;
}

h2{
	.h2;
}

h3{
	.h3;
}

h4{
	.h4;
}

h5{
	.h5;
}

h6{
	.h6;
}

p{

}

a{

}

img{
	max-width: 100%;
	height: auto;
}