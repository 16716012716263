.block-slider{
  margin-top: @x3*2;

  &__content{

    h3, p{
      /*.center;

      @media @notebook{
        text-align: left;
      }*/
    }

  }

  .slick-slide{

    &:focus, &:focus-visible{
      outline: 0;
    }
  }

  .slick-list{
    margin: 0 -@x1;
  }

  img{
    padding: 0 @x1;
  }
}