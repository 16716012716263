//
// Visibility utilities
//

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}
