body{
	background-color: @body;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;

	--gallery-block--gutter-size: 3rem;
}

.wrapper{
	flex: 1;
	padding-top: 80px;

	@media @notebook{
		padding-top: 115px;
	}
}

.clear{
	clear: both;
}

.hidden{
	display: none;
}

.transition{
	transition: 0.3s all;
}

.center{
	text-align: center;
}

.left{
	text-align: left;
}

.right{
	text-align: right;
}

.fleft{
	float: left;
}

.fright{
	float: right;
}

:where(.wp-block-columns.is-layout-flex){
	gap: 3rem;
}

.wp-block-columns{

	&.large-gap{
		align-items: center !important;
		gap: 3rem;

		@media @desktop{
			gap: 13rem;
		}
	}

	&.reverse{
		flex-direction: column-reverse;

		@media (min-width: 782px){
			flex-direction: row;
		}
	}
}

p + .wp-block-buttons{
	margin-top: @x3;
}

.has-medium-font-size{
	font-size: 2.2rem;
}

html[lang="de-DE"], html[lang="en-GB"]{
	.header__sidebar .wp-block-buttons{
		display: none !important;
	}

	.header__sidebar select{
		margin-right: 0;
	}

	.block-hero h1{
		margin-bottom: 0;
	}
}