.wp-block-quote{
  margin-bottom: @x3;
  padding-left: 6rem;
  position: relative;

  .icon-pseudo(quote-left, before);

  &:before{
    position: absolute;
    left: 0;
    top: -@x1;
    font-size: @x3;
  }

  & + p{
    margin-bottom: 0;
    padding-left: 6rem;

    & + p{
      padding-left: 6rem;
      margin-bottom: 0;
    }
  }
}