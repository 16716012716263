.footer{
  background-color: @primary;
  color: @white;
  padding: @x3 0;
  .center;

  a{
    color: @white;

    i{
      font-size: 3rem;
      margin: 0 1.5rem;
    }
  }

  &__copy{
    margin-top: @x2;
  }
}