.header{
  background-color: @white;
  padding: @x05 0;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 9;
  box-shadow: 0 0 5px 2px fade(@black, 10);

  @media @notebook{
    height: 115px;
  }

  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo{

    img{
      width: 100px;

      @media @notebook{
        width: 155px;
      }
    }
  }

  &__hamburger{

    @media @notebook{
      display: none;
    }
  }

  &__menu{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s opacity;
    background-color: @primary;
    list-style-type: none;
    margin: 0;
    padding: @x3 @x1 210px @x1;
    left: 0;
    top: 80px;
    height: calc(100vh - 80px);
    width: 100%;
    .center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media @notebook{
      opacity: 1;
      visibility: visible;
      position: relative;
      top: auto;
      left: auto;
      background-color: transparent;
      flex-direction: row;
      padding: 0;
      height: initial;
      width: initial;
    }

    & > li{
      @media @desktop{
        margin-right: @x2;
      }

      &:last-child{
        @media @desktop{
          margin-right: 0;
        }
      }

      & > a{
        display: inline-block;
        padding: @x05;
        color: @white;
        font-size: 2rem;
        text-decoration: none;
        position: relative;
        transition: 0.3s all;

        @media @notebook{
          color: @color;
        }

        &:after{
          content: "";
          position: absolute;
          width: calc(100% - @x1);
          height: 1px;
          background-color: @primary;
          bottom: 8px;
          left: @x05;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s all;
        }

        &:hover{
          text-decoration: none;
          color: @white;

          @media @notebook{
            color: @primary;
          }

          &:after{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &__sidebar{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    bottom: calc(-100vh + 128px);
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: @x3;

    @media @notebook{
      position: relative;
      opacity: 1;
      visibility: visible;
      left: auto;
      right: auto;
      bottom: auto;
      width: initial;
      flex-direction: row;
      padding-bottom: 0;
      margin: 0;
    }

    select{
      margin-bottom: @x1;
      text-transform: uppercase;
      height: 40px;
      border: 1px solid @black;
      padding: 0 @x1;

      @media @notebook{
        margin-bottom: 0;
        margin-right: @x1;
      }

      @media @desktop{
        margin-right: @x3;
      }

      &:hover, &:focus {
        outline: 0;
      }
    }
  }
}

body.admin-bar-showing{
  .header{
    top: 46px;

    @media screen and (min-width: 782px){
      top: 32px;
    }
  }
}

body.menu-open{
  height: 100%;
  overflow-y: hidden;

  .header__menu{
    opacity: 1;
    visibility: visible;
  }

  .header__sidebar{
    opacity: 1;
    visibility: visible;
  }
}