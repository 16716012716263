// CSS image replacement
#text-hide(@ignore-warning) {
	font: ~"0/0 a";
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;

	#deprecate("`#text-hide()`", "v4.1.0", "v5", @ignore-warning);
}
