.block-hero{
  position: relative;
  background-size: cover;
  background-position: 50% 30%;
  background-color: @primary;

  .lazyblock-inner-blocks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .center;
    padding: @x5 0;

    @media @notebook{
      min-height: 500px;
    }

    & > *{
      position: relative;
      z-index: 1;
    }
  }

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(@primary, 75);
  }

  h1{
    color: @white;
    margin-bottom: @x1;

    @media @notebook{
      margin-bottom: @x3;
    }

  }
}