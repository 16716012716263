//@each $color, $value in $theme-colors {
//  @include bg-variant('.bg-#{$color}', $value);
//}
each(@theme-colors, #(@value, @color) {
	#bg-variant(~".bg-@{color}", @value);
})

//@if $enable-gradients {
//  @each $color, $value in $theme-colors {
//    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
//  }
//}
& when (@enable-gradients) {
	each(@theme-colors, #(@value, @color) {
		#bg-gradient-variant(~".bg-gradient-@{color}", @value);
	})
}


.bg-white {
	background-color: @white !important;
}

.bg-transparent {
	background-color: transparent !important;
}
