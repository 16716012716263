.btn{
  display: inline-block;
  text-transform: uppercase;
  padding: @x05 @x3;
  text-decoration: none;
  outline: 0;
  transition: 0.3s all;
  border-radius: 0;

  &:focus{
    outline: 0;
  }

  &:hover{
    text-decoration: none;
  }
}

.btn-primary{
  .btn;
  background-color: @secondary;
  color: @white;

  &:hover{
    background-color: @secondary_hover;
  }
}

//wp-block-button fix to work with classes
.wp-block-button{
  padding: 0;

  .wp-block-button-link{

  }

  &.btn-primary{
    .wp-block-button__link{
      .btn-primary;
    }
  }
}