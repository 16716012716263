.block-cta{
  position: relative;
  background-size: cover;
  background-position: 50% 30%;
  background-color: @primary;
  color: @white;
  padding: @x3*2;

  .lazyblock-inner-blocks{

    & > *{
      position: relative;
      z-index: 1;
    }
  }

  p{
    margin-bottom: 0;
  }

  .wp-block-columns{
    margin-bottom: 0;
    align-items: center !important;
  }

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(@primary, 85);
  }

  .wp-block-columns{
    flex-direction: column;
    .center;

    @media @notebook{
      text-align: left;
      flex-direction: row;
    }
  }
}